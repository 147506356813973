import type {ReactNode} from 'react';
// @ts-ignore
import type {TFunction} from 'react-i18next';
import PeopleIcon from "@mui/icons-material/People";
import SchoolIcon from "@mui/icons-material/School";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import BoltIcon from '@mui/icons-material/Bolt';
import {Session} from "../api/sessions";
import {Major} from "../api/majors";
import {getUrl, paths} from "../paths";

interface Item {
  disabled?: boolean;
  icon?: ReactNode;
  items?: Item[];
  label?: ReactNode;
  path?: string;
  title: string;
}

export interface Section {
  items: Item[];
  subheader?: string;
}

export const getSections = (t: TFunction, sessions: Session[], majors: Major[]): Section[] => {

  const sessionsItems = sessions.map(session => {
    const path = getUrl(paths.sessions.detail, {id: session.id})
    return {
      major: session.major,
      item: {
        title: session.name,
        path: path
      }
    }
  })

  const majorsItems = majors.map(major => {
    const path = getUrl(paths.majors.detail, {id: major.id})
    return {
      title: major.name,
      path: path,
      items: sessionsItems.filter(item => item.major === major.id).map(item => item.item)
    }
  })

  return [
    {
      subheader: t('General'),
      items: [
        {
          title: t('Les candidatures'),
          path: paths.applications.index,
          icon: <PeopleIcon fontSize="small" />
        },
        {
          title: t('Les promotions'),
          path: paths.sessions.index,
          icon: <SchoolIcon/>,
          items: majorsItems
        },

      ]
    },
    {
      subheader: t('Opérations'),
      items : [
        {
          title: t('Actions rapides'),
          path: paths.operations.index,
          icon: <BoltIcon fontSize="small" />
        }
      ]
    },
    {
      subheader: t('Paramètres'),
      items: [
        {
          title: t('Mon compte'),
          path: paths.account,
          icon: <AccountCircleIcon fontSize="small" />
        }
      ]
    }
  ];
}

