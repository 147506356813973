import type {FC, ReactNode} from 'react';
import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {useSettings} from '../hooks/use-settings';
import {HorizontalLayout} from './horizontal-layout';
import {VerticalLayout} from './vertical-layout';
import type {Section} from './config';
import {getSections} from './config';
import {useSessionsListQuery} from "../api/sessions";
import {useMajorsListQuery} from "../api/majors";

const useTranslatedSections = (): Section[] => {
  const { t } = useTranslation();
  const {data: sessionsRaw = []} = useSessionsListQuery()
  const {data: majorsRaw = []} = useMajorsListQuery()

  const sessions = [...sessionsRaw].sort(((a, b) => a.name.localeCompare(b.name)))
  const majors = [...majorsRaw].sort(((a, b) => a.name.localeCompare(b.name)))

  return useMemo(
    () => getSections(t, sessions, majors),
    [t, majors, sessions]
  );
};

interface LayoutProps {
  children?: ReactNode;
}

export const Layout: FC<LayoutProps> = (props) => {
  const settings = useSettings();
  const sections = useTranslatedSections();

  if (settings.layout === 'horizontal') {
    return (
      <HorizontalLayout
        sections={sections}
        navColor={settings.navColor}
        {...props}
      />
    );
  }

  return (
    <VerticalLayout
      sections={sections}
      navColor={settings.navColor}
      {...props}
    />
  );
};

Layout.propTypes = {
  children: PropTypes.node
};
